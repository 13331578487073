/*
  Color palette
- blues: #1e6dd8 ; darker: #00449f ; lighter #daeaff ; box-shadow: #cfd9ed
- oranges: #ffab91 ; darker: #c97b63 ; lighter: #ffddc1
- foreground: #fff ; background: #f4f8fb
- text color: #6a6a6b;
- border color: lighter #e3e3e3 ; darker #6a6a6b
- green: #40ce24 ; red: #ff3131
*/

/*
  General definitions
*/

* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

body {
    font-size: 18px;
    background-color: #f4f8fb;
    color: #6a6a6b;
}

/*
   Elements:
*/

h2 {
    font-size: 30px;
    font-family: 'Roboto-Light';
    font-weight: normal;
}

h3 {
    font-family: 'Roboto-Light';
    font-weight: normal;
}

a {
    text-decoration: none;
}

input[type="text"], input[type="password"] {
    display: inline-block;

    font-family: 'NunitoSans-ExtraLight';
    color: #000;

    padding: 14px 10px;

    border: 1px solid #e3e3e3;
    border-radius: 10px;
    outline: none;
}

select {
    width: 250px;
    padding: 5px 15px;
    background: none;

    border: 1px solid #e3e3e3;
    border-radius: 10px;
}

button {
    display: inline-block;
    background-color: #1e6dd8;

    padding: 0 10px;
    height: 50px;
    line-height: 50px;

    font-family: 'NunitoSans-Regular';
    color: #fff;

    border: 1px solid #e3e3e3;
    border-radius: 10px;

    box-shadow: 0px 0px 20px -5px #6a6a6b;
}

button:hover {
    cursor: pointer;
}

button:disabled {
    opacity: 0.3;
    cursor: auto;
}

table {
    table-layout: fixed;
    width: 90%;
    min-width: 750px;
    margin: 30px auto 60px;

    border-collapse: collapse;
}

.big-table {
    font-size: 16px;
}

th {
    height: 50px;
    position: relative;
    padding: 5px 20px;
}

td {
    padding: 10px;
}

tr {
    border-bottom: 2px solid #e3e3e3;
}

tbody tr:nth-child(odd) {
    background-color: #f4f8fb;
}

tbody tr:last-child {
    border-bottom: none;
}

.error-message {
    color: #ff3131;
    font-size: 25px;
    margin-top: 20px;
}

img.logo {
    margin: 0 auto;
    height: 400px; /*logo-height: label, do not remove*/
    width: 400px; /*logo-width: label, do not remove*/
}


/*
  Sections:
*/

section {
    position: relative;

    width: 80%;
    min-width: 850px;
    margin: 20px auto;
    padding: 20px;

    background-color: #fff;
    box-shadow: 0px 0px 20px -6px #cfd9ed;
    border-radius: 2px;

    text-align: center;
}

section.search {
    background-color: #fff;
    background-image: url('assets/images/LogosAWS@2x.png');
    background-repeat: no-repeat;
    background-position: right 24%;
    background-size: auto 10%; /* Adjust size as needed */
}

/* Floating button */

.floatingButton {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 10;

    opacity: 0.8;

    width: 80px;
    height: 80px;
    border-radius: 50%;
}

/* Login */

#login div {
    margin: 20px auto;
}

#login label {
    display: inline-block;
    width: 150px;
}

#logout {
    position: absolute;
    right: 10px;
    top: 10px;
}

/* Search */

input[type="text"].search {
    width: 600px;
    margin-right: 10px;
    padding-left: 45px;

    border-radius: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

button.search {
    width: 120px;

    background-image: url('assets/images/legacy/search-icon-hover.png');
    background-position: 8px 14px;
    background-repeat: no-repeat;

    border-radius: 0px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.about-notice {
    text-align: left;
}

div.about {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

/* Confirm Address */

#map {
    width: 80%;
    min-width: 600px;
    min-height: 365px;
    margin: 20px auto;

    border-radius: 15px;
    border: 3px solid #e2e7f1;
}

/* Search property details */

#property-details div {
    width: 750px;
    margin: 40px auto;
}

.left-aligned {
    text-align: left;
}

.form-header{
    display: inline-block;
    width: 260px;
}

.radio-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.radio-btn+label {
    display: inline-block;
    min-width: 110px;
    margin: 2px;
    padding: 10px;

    text-align: center;

    border: 1px solid #e3e3e3;
}

.radio-btn:disabled+label {
    color: #e3e3e3;
}

.radio-btn+label:hover {
    cursor: pointer;
}

.radio-btn:focus+label{
    border: 1px solid black;
}

.radio-btn:checked+label {
    color: #fff;
    background-color: #1e6dd8;
    box-shadow: 0px 0px 10px #cfd9ed;
}

div.array {
    width: 500px;
    margin: 20px auto;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

/* Agent/agency rankings page */

#rankings-tabs .tab {
    margin: 0 5px;

    color: #000;
    background: none;
    box-shadow: 0px 0px 20px -6px #cfd9ed;
}

#rankings-tabs .tab.active {
    color: #fff;
    background-color: #1e6dd8;
}

.clickable:hover {
    color: #fff;
    background-color: #1e6dd8;
    cursor: pointer;
}

th.desc, th.asc {
    color: #fff;
    background-color: #1e6dd8;
}

th.desc::after {
    position: absolute;
    top: 20px;
    right: 2px;
    content: '▼';
}

th.asc::after {
    position: absolute;
    top: 20px;
    right: 2px;
    content: '▲';
}

tbody tr.highlight {
    color: #fff;
    background-color: #1e6dd8;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the h3 */
    position: relative;
}

.right-align {
    align-self: flex-end; /* Align the p to the right */
    width: 100%; /* Ensure p takes the full width to align to the right */
    text-align: right;
    margin-right: 40px;
}

/* Analytics actions */

#analytics-actions {
    margin-top: 30px;
    text-align: right;
}

#analytics-actions p {
    display: inline-block;

    margin: 0 10px;
    padding: 5px 20px;
    padding-left: 35px;

    color: #505050;

    border: 1px solid #505050;
    border-radius: 25px;
}

#analytics-actions p.print {
    background-image: url('assets/images/legacy/print-icon.png');
    background-position: 12px 6px;
    background-repeat: no-repeat;
}

#analytics-actions p.email{
    background-image: url('assets/images/legacy/email-icon.png');
    background-position: 12px 8px;
    background-repeat: no-repeat;
}

#analytics-actions p.save {
    background-image: url('assets/images/legacy/save-icon.png');
    background-position: 12px 5px;
    background-repeat: no-repeat;
}

/* Text and background highlights */

.margined {
    margin: 30px auto;
}

.text-highlight {
    font-family: 'NunitoSans-Bold';
}

.text-slight {
    color: #6a6a6b;
    font-style: italic;
}

.text-big {
    font-size: 26px;
}

.text-small {
    font-size: 14px;
}

.text-green {
    color: #40ce24;
}

.text-red {
    color: #ff3131;
}

.text-light-red {
    color: #ff6969;
}


/* Spinning loader widget, from: https://loading.io/css/ */

div#lds-roller {
  position: relative;
  width: 80px;
  height: 80px;
  text-align: center;
  margin: 0 auto;
}
#lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
#lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #1e6dd8;
  margin: -4px 0 0 -4px;
}
#lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
#lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
#lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
#lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
#lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
#lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
#lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
#lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
#lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
#lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
#lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
#lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
#lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
#lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
#lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
#lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

