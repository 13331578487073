/*
  Fonts:
*/

@font-face {
	font-family: 'NunitoSans-ExtraLight';
	src: url('assets/fonts/NunitoSans-ExtraLight.eot');
	src: local('☺'), url('assets/fonts/NunitoSans-ExtraLight.woff') format('woff'), url('assets/fonts/NunitoSans-ExtraLight.ttf') format('truetype'), url('assets/fonts/NunitoSans-ExtraLight.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'NunitoSans-Bold';
	src: url('assets/fonts/NunitoSans-Bold.eot');
	src: local('☺'), url('assets/fonts/NunitoSans-Bold.woff') format('woff'), url('assets/fonts/NunitoSans-Bold.ttf') format('truetype'), url('assets/fonts/NunitoSans-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto-Light';
	src: url('assets/fonts/Roboto-Light.eot');
	src: local('☺'), url('assets/fonts/Roboto-Light.woff') format('woff'), url('assets/fonts/Roboto-Light.ttf') format('truetype'), url('assets/fonts/Roboto-Light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NunitoSans-Regular';
	src: url('assets/fonts/NunitoSans-Regular.eot');
	src: local('☺'), url('assets/fonts/NunitoSans-Regular.woff') format('woff'), url('assets/fonts/NunitoSans-Regular.ttf') format('truetype'), url('assets/fonts/NunitoSans-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'NunitoSans-Light';
	src: url('assets/fonts/NunitoSans-Light.eot');
	src: local('☺'), url('assets/fonts/NunitoSans-Light.woff') format('woff'), url('assets/fonts/NunitoSans-Light.ttf') format('truetype'), url('assets/fonts/NunitoSans-Light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
